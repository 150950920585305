import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { ClientForm } from '../../models/ClientForm';
import { FormDefaultConfiguration } from '../../models/ClientFormDefaults';
import { ClientFormUser } from '../../models/ClientFormUser';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import ClientFormService from '../../services/ClientFormService';
import ClientService from '../../services/ClientService';
import DateUtils from '../../utils/DateUtils';
import SingleUserSelect from '../ownership/SingleUserSelect';
import DataImportWizard from '../shared/data-import/DataImportWizard';
import { ButtonType } from '../shared/form-control/Button';
import { DatePicker, DatePickerType } from '../shared/form-control/DatePicker';
import { Input } from '../shared/form-control/Input';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import usePermissions from '../../hooks/permissions/usePermissions';
import { Roles } from '../../models/Role';
import { useSearchParams } from 'react-router-dom';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';

type NewTaskModalProps = {
  open: boolean;
  modalHeader: string;
  modalSubtitle?: string;
  templateId: string;
  templateFormName: string;
  moduleId?: string;
  defaults?: FormDefaultConfiguration;
  hideOwner?: boolean;
  hideDueDate?: boolean;
  predefinedSubtitle?: string | JSX.Element[];
  onClose: () => void;
  onCreated: (clientForm: ClientForm) => void;
} & (
  | {
      enableImporting: true;
      templateClient: string | null;
      onRetry: () => void;
    }
  | { enableImporting?: false }
);

const NewTaskModal: FC<NewTaskModalProps> = (props) => {
  const {
    open,
    onClose,
    onCreated,
    templateId,
    templateFormName,
    modalHeader,
    modalSubtitle,
    moduleId,
    defaults,
    enableImporting,
    hideOwner: hideOwnerProp,
    hideDueDate,
    predefinedSubtitle,
  } = props;
  const [createFormTitle, setCreateFormTitle] = useState('');
  const [createFormDueDate, setCreateFormDueDate] = useState<Date | null>(null);
  const [createFormOwner, setCreateFormOwner] = useState<ClientFormUser | undefined>(undefined);
  const [scheduledFormDate, setScheduledFormDate] = useState<Date | null>(null);
  const [hideOwner, setHideOwner] = useState(!!hideOwnerProp);
  const [busyCreating, setBusyCreating] = useState(false);
  const [importMode, setImportMode] = useState(false);
  const client = useCurrentClient((x) => x.value);
  const { t } = useTranslation(['module', 'common', 'data-import-export']);
  const currentUser = useCurrentUser((x) => x.value);
  const toasts = useToasts();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(
    function triggerImportFromUrl() {
      if (!searchParams.get('import')) return;

      setImportMode(true);
      setSearchParams((prev) => {
        prev.delete('import');
        return prev;
      });
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    setCreateFormDueDate(
      defaults?.dueDateIntervalAmount !== undefined && defaults?.dueDateInterval !== undefined
        ? DateUtils.setDate(defaults?.dueDateIntervalAmount, defaults?.dueDateInterval)
        : null,
    );
  }, [defaults?.dueDateInterval, defaults?.dueDateIntervalAmount]);

  useEffect(() => {
    if (defaults?.creatorRole === ClientFormUserRole.Owner) {
      if (currentUser) {
        setCreateFormOwner({
          ...currentUser,
          role: ClientFormUserRole.Owner,
        });
        setHideOwner(true);
      }
    } else {
      const ownerId = defaults?.users && defaults?.users.find((user) => user.role === ClientFormUserRole.Owner)?.userId;
      if (ownerId) {
        ClientService.getUsers().then((res) => {
          const user = res.find((x) => x.id === ownerId);
          if (user) {
            setCreateFormOwner({
              ...user,
              role: ClientFormUserRole.Owner,
            });
            setHideOwner(true);
          }
        });
      }
    }
  }, [client?.id, currentUser, defaults?.creatorRole, defaults?.users]);

  const internalOnClose = useCallback(() => {
    setCreateFormTitle('');
    setCreateFormDueDate(null);
    setScheduledFormDate(null);
    setCreateFormOwner(undefined);
    setImportMode(false);
    onClose();
  }, [onClose]);

  const provisionForm = () => {
    if (client) {
      setBusyCreating(true);
      ClientFormService.allocateToClient({
        templateFormId: templateId,
        clientId: client.id,
        subtitle: createFormTitle,
        dueDateUtc: createFormDueDate,
        startDateUtc: scheduledFormDate,
        ownerId: createFormOwner?.id,
        allowNoOwner: true,
        clientModuleId: moduleId,
      })
        .then((res) => {
          onCreated(res.data);
        })
        .catch((err) => {
          if (err.status === 403) {
            toasts.addToast({
              title: t('common:errors.forbidden.subtitle'),
              description: t('common:errors.forbidden.description'),
              type: ToastType.ERROR,
              expiresInMs: 5000,
            });
          }
        })
        .finally(() => {
          setBusyCreating(false);
          internalOnClose();
        });
    }
  };

  const hasPermission = usePermissions();
  const canImport = useMemo(() => hasPermission(Roles.TeamLead), [hasPermission]);

  return (
    <ModalContext.Provider value={{ open: open, onClose: internalOnClose, modalWidth: importMode ? 'lg:w-2/5 w-1/2 min-w-[600px]' : 'w-2/6' }}>
      {!importMode ? (
        <StandardModal
          title={modalHeader}
          subTitle={modalSubtitle}
          cancelButtonTitle={t('module:buttons.cancel')}
          confirmButtonTitle={t('module:buttons.create')}
          onCancelClick={internalOnClose}
          onConfirmClick={provisionForm}
          confirmDisabled={(!createFormTitle && !predefinedSubtitle) || busyCreating}
          confirmLoading={busyCreating}
          tertiaryButtonTitle={canImport ? t('data-import-export:import.trigger-button') : undefined}
          onTertiaryButtonClick={canImport && enableImporting ? () => setImportMode(true) : undefined}
          tertiaryButtonIcon={null}
        >
          <div className="py-4">
            <div className="my-1 p-2">
              <div className="mb-2 font-medium text-black">{t('module:new-form-modal.choose-name')}</div>
              {predefinedSubtitle ? (
                <span className="text-primary-1 mt-4">{predefinedSubtitle}</span>
              ) : (
                <Input data-cy="create-form-title" value={createFormTitle} onChange={(e) => setCreateFormTitle(e.target.value)} maxLength={100} />
              )}
            </div>
            {!hideDueDate && !defaults?.dueDateIntervalAmount && !defaults?.dueDateInterval && (
              <div className="my-1 flex justify-between p-2">
                <div className="mb-2 font-medium text-black">{t('module:new-form-modal.choose-due-date')}</div>
                <DatePicker
                  data-cy="create-from-due-date-picker"
                  type={createFormDueDate ? DatePickerType.LINK : DatePickerType.BUTTON}
                  buttonType={ButtonType.SECONDARY}
                  buttonContent={
                    <>{createFormDueDate ? DateUtils.formatDate(new Date(createFormDueDate)) : <>{t('module:buttons.select-date')}</>}</>
                  }
                  date={createFormDueDate || new Date()}
                  onChange={(date) => {
                    setCreateFormDueDate(date);
                  }}
                ></DatePicker>
              </div>
            )}
            {!hideOwner && (
              <div className="my-1 flex justify-between p-2">
                <div className="mb-2 font-medium text-black">{t('module:new-form-modal.choose-owner')}</div>
                <SingleUserSelect user={createFormOwner} onAddUser={setCreateFormOwner} onRemoveUser={() => setCreateFormOwner(undefined)} />
              </div>
            )}
          </div>
        </StandardModal>
      ) : (
        <DataImportWizard
          templateFormId={templateId}
          templateFormName={templateFormName}
          templateFormClient={enableImporting ? props.templateClient : null}
          onCancel={() => setImportMode(false)}
          onRetry={() => {
            setImportMode(true);
            'onRetry' in props && props.onRetry();
          }}
        />
      )}
    </ModalContext.Provider>
  );
};

export default NewTaskModal;
