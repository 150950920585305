import StandardModal from '../modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { useDataImportWizard } from '../../../contexts/DataImportContext';
import { indexToExcelColumn, excelColumnToIndex } from '../../../utils/StringUtils';
import WizardStepsIndicator from '../wizard/WizardStepsIndicator';
import { useCallback, useEffect, useState } from 'react';
import { Input } from '../form-control/Input';
import DataImportService from '../../../services/DataImportService';
import DropdownSelect from '../form-control/DropdownSelect';
import { Item } from '../form-control/DropdownDefaultComponents';
import { DataJobType } from '../../../models/DataImport';
import SkeletonLoader from '../skeleton-loader/SkeletonLoader';
import { useCurrentClient } from '../../../global-state/Clients';

const SheetSelections = () => {
  const { nextStep, wizardState, setWizardState, prevStep, templateFormId, stepNames } = useDataImportWizard();
  const { jobId, rowForHeadings, rowForData, colForTitles, sheetIndex } = wizardState;
  const [sheetOptions, setSheetOptions] = useState<Item[]>([]);
  const [saving, setSaving] = useState(false);
  const currentClient = useCurrentClient((x) => x.value);
  const [isFetchingSheets, setIsFetchingSheets] = useState(false);

  const { t } = useTranslation('data-import-export');

  const rowNumberFilter = useCallback((e: KeyboardEvent) => {
    if (!/^[0-9]$/.test(e.key) && !['Backspace', 'Tab', 'Delete'].includes(e.key)) {
      e.preventDefault();
    }
  }, []);

  const columnNameFilter = useCallback((e: KeyboardEvent) => {
    if (!/^[a-zA-Z]$/.test(e.key) && !['Backspace', 'Tab', 'Delete'].includes(e.key)) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (jobId) {
      setIsFetchingSheets(true);
      DataImportService.getSheets(jobId)
        .then((res) => {
          setSheetOptions(
            Object.entries(res.data).map(([key, value]) => {
              return { id: value, text: value, value: key };
            }),
          );
        })
        .finally(() => {
          setIsFetchingSheets(false);
        });
    }
  }, [jobId]);

  const saveProperties = useCallback(() => {
    setSaving(true);
    DataImportService.setJobProperties(wizardState.jobId as string, {
      dataRowIndex: (wizardState.rowForData ?? 0) - 1,
      headerRowIndex: (wizardState.rowForHeadings ?? 0) - 1,
      titleColumnIndex: wizardState.colForTitles ?? 0,
      importType: DataJobType.DataOnly,
      sheetIndex: sheetIndex,
      templateId: templateFormId,
      clientId: currentClient?.id,
    }).then(() => {
      setSaving(false);
      nextStep('mapping');
    });
  }, [
    currentClient?.id,
    nextStep,
    sheetIndex,
    templateFormId,
    wizardState.colForTitles,
    wizardState.jobId,
    wizardState.rowForData,
    wizardState.rowForHeadings,
  ]);

  return (
    <StandardModal
      title={t('import.selection-step.heading')}
      subTitle={t('import.selection-step.subheading')}
      cancelButtonTitle={t('import.selection-step.buttons.cancel')}
      onCancelClick={prevStep}
      confirmButtonTitle={t('import.selection-step.buttons.next')}
      onConfirmClick={saveProperties}
      confirmLoading={saving}
      confirmDisabled={rowForHeadings === null || rowForData === null || colForTitles === null || !jobId || isFetchingSheets}
    >
      <WizardStepsIndicator activeStepIndex={1} stepNames={stepNames} onStepChange={nextStep} />

      <div className="min-h-80">
        <SkeletonLoader ready={!isFetchingSheets} type="inputField" rows={4}>
          <>
            <div className="text-dpm-12 mt-4">{t('import.selection-step.inputs.sheet-label')}</div>
            <DropdownSelect
              options={sheetOptions}
              onChange={(o) => {
                setWizardState((prev) => ({ ...prev, sheetIndex: o.value as number }));
              }}
              isFetching={isFetchingSheets}
              value={sheetOptions[wizardState.sheetIndex] || sheetOptions[0]}
              searchable
            />
            <div className="text-dpm-12 mt-4">{t('import.selection-step.inputs.heading-input-label')}</div>
            <Input
              value={rowForHeadings?.toString() || ''}
              type="number"
              onChange={(e) => setWizardState((prev) => ({ ...prev, rowForHeadings: e.target.value.trim() ? parseInt(e.target.value, 10) : null }))}
              placeholder={t('import.selection-step.inputs.heading-input-placeholder')}
              onKeyPress={rowNumberFilter}
              disabled={isFetchingSheets}
            />

            <div className="text-dpm-12 mt-4">{t('import.selection-step.inputs.data-input-label')}</div>
            <Input
              value={rowForData?.toString() || ''}
              type="number"
              onChange={(e) => setWizardState((prev) => ({ ...prev, rowForData: e.target.value.trim() ? parseInt(e.target.value, 10) : null }))}
              placeholder={t('import.selection-step.inputs.data-input-placeholder')}
              onKeyPress={rowNumberFilter}
              disabled={isFetchingSheets}
            />

            <div className="text-dpm-12 mt-4">{t('import.selection-step.inputs.form-title-input-lable')}</div>
            <Input
              value={indexToExcelColumn(colForTitles)}
              onChange={(e) => setWizardState((prev) => ({ ...prev, colForTitles: excelColumnToIndex(e.target.value.toUpperCase()) }))}
              placeholder={t('import.selection-step.inputs.form-title-input-placeholder')}
              onKeyPress={columnNameFilter}
              disabled={isFetchingSheets}
            />
          </>
        </SkeletonLoader>
      </div>
    </StandardModal>
  );
};

export default SheetSelections;
